<template>
  <div>
    <div class="title">
      <div class="title_jl">在线简历</div>
      <div class="title_yl pointer" @click="previewClick">预览</div>
    </div>
    <div class="active">
      <div class="activeText" v-if="Person">
        <div v-if="Person.detail && updateShow" class="activeTextr">
          <div class="activeName">
            <div class="nameleft">{{ Person.name }}</div>
            <div
              class="position-relative el-nameimg-posi"
              @mouseenter="avatarShow = true"
              @mouseleave="avatarShow = false"
            >
              <img class="el-nameimg pointer" :src="avatar_url" v-if="avatar_url" />
              <img
                class="el-nameimg pointer"
                :src="Person.avatar_url"
                v-if="!avatar_url && Person.avatar_url"
              />
              <img
                class="el-nameimg pointer"
                src="../../../assets/login/922028.png"
                v-if="!avatar_url && !Person.avatar_url"
              />
              <!--   -->
              <div
                class="position-absolute flex-center pointer el-divider"
                v-show="avatarShow"
              >
                <el-upload
                  class="upload-demo"
                  action=""
                  :show-file-list="false"
                  accept=".jpg,.png,.jpeg"
                  :http-request="uploadMainImg"
                >
                </el-upload>
              </div>
            </div>
          </div>
          <div class="activeExperience">
            <div class="active_jingyan">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience">{{ Person.other_info.work_year }}年经验</div>
            </div>
            <div class="active_xt"></div>
            <div class="active_jingyan">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience" v-if="Person.school.length > 0">
                {{ Person.school[0].education }}
              </div>
              <div class="experience" v-else>无</div>
            </div>
            <div class="active_xt" v-if="Person.other_info.job_status"></div>
            <div class="active_jingyan" v-if="Person.other_info.job_status">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience">{{ Person.other_info.job_status }}</div>
            </div>
          </div>
          <div class="activeExperience space-between" style="margin-top: 10px">
            <div class="row-center">
              <div class="active_jingyan">
                <i class="el-icon-suitcase experience_icon"></i>
                <div class="experience">{{ Person.other_info.age }}岁</div>
              </div>
              <div class="active_xt"></div>
              <div class="active_jingyan">
                <i class="el-icon-suitcase experience_icon"></i>
                <div class="experience">{{ Person.phone }}</div>
              </div>
            </div>
            <div class="row-center pointer" @click="updateShow = false">
              <img src="../../../assets/glxt/9.png" alt="" />
              <span class="text-blue font-normal">修改</span>
            </div>
          </div>
        </div>
        <div v-else class="redact">
          <div class="active_bianji">
            <div class="redact_title">编辑个人信息</div>
            <div class="redact_xinxi">
              <div class="xinxi_left">
                <div class="xinxi_xingming">姓名</div>
                <div class="xinxi_flex">
                  <el-input
                    class="flex_input"
                    v-model="query.name"
                    placeholder="请输入您的姓名"
                  ></el-input>
                  <el-checkbox class="flex_tab" v-model="nameShow" @change="nameChange"
                    >隐藏姓名</el-checkbox
                  >
                </div>
              </div>
              <div class="xinxi_right">
                <div class="xinxi_xingming">当前求职状态</div>
                <div class="xinxi_flex">
                  <el-select
                    size="small"
                    v-model="query.job_status"
                    placeholder="请选择"
                    class="flex_inputs"
                  >
                    <el-option
                      v-for="item in jobList"
                      :key="item.index"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="redact_xinxi">
              <div class="xinxi_left">
                <div class="xinxi_xingming">性别</div>
                <div
                  class="xinxi_flex"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div
                    class="xingming_nan pointer"
                    :class="query.gender == 1 ? 'gender-index' : ''"
                    @click="genderClick(1)"
                  >
                    男
                  </div>
                  <div
                    class="xingming_nan pointer"
                    :class="query.gender == 2 ? 'gender-index' : ''"
                    @click="genderClick(2)"
                  >
                    女
                  </div>
                </div>
              </div>
              <div class="xinxi_right">
                <div class="xinxi_xingming">我的身份</div>
                <div class="xinxi_flex">
                  <el-select
                    size="small"
                    v-model="query.identity"
                    placeholder="请选择"
                    class="flex_inputs"
                  >
                    <el-option
                      v-for="item in identity"
                      :key="item.index"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="redact_xinxi">
              <div class="xinxi_left">
                <div class="xinxi_xingming">出生年月</div>
                <div class="xinxi_flex">
                  <el-date-picker
                    class="flex_inputs"
                    v-model="query.birthday"
                    :picker-options="pickerOptions"
                    type="month"
                    size="small"
                    placeholder="选择日期"
                    value-format="yyyy-MM"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="xinxi_right">
                <div class="xinxi_xingming">参加工作时间</div>
                <div class="xinxi_flex">
                  <el-date-picker
                    class="flex_inputs"
                    v-model="query.work_time"
                    :picker-options="pickerOptions"
                    type="month"
                    size="small"
                    placeholder="选择日期"
                    value-format="yyyy-MM"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div class="redact_xinxi">
              <div class="xinxi_left">
                <div class="xinxi_xingming">手机号码</div>
                <div class="xinxi_flex" style="display: flex; align-items: center">
                  <el-input
                    class="flex_input"
                    v-model="query.s_phone"
                    type="number"
                    :maxlength="11"
                    placeholder="请输入您的手机号码"
                  ></el-input>
                  <el-checkbox class="flex_tab" v-model="phoneShow" @change="phoneChange"
                    >隐藏手机号码</el-checkbox
                  >
                </div>
              </div>
              <div class="xinxi_right">
                <div class="xinxi_xingming">微信（选填）</div>
                <div class="xinxi_flex">
                  <el-input
                    size="mini"
                    v-model="query.wx_account"
                    placeholder="请输入微信号"
                    class="flex_input"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="redact_xinxi">
              <div class="xinxi_left">
                <div class="xinxi_xingming">现居住地</div>
                <div class="xinxi_flex">
                  <el-cascader
                    size="small"
                    :props="{ value: 'id' }"
                    :options="addressList"
                    placeholder="请选择地址"
                    class="flex_inputs"
                    @change="handleChange"
                    v-model="addressValue"
                    ref="addressCascader"
                  >
                  </el-cascader>
                </div>
              </div>
              <div class="xinxi_right">
                <div class="xinxi_xingming">邮箱（选填）</div>
                <div class="xinxi_flex">
                  <el-input
                    size="mini"
                    class="flex_input"
                    v-model="query.s_email"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
            </div>
            <div
              class="qzxz_right"
              style="
                margin-top: 28px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <div class="right_qx pointer" @click="goBack">取消</div>
              <div class="right_wc pointer" @click="submitClick">完成</div>
            </div>
          </div>
        </div>
        <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">个人优势</div>
            <div
              class="top_right pointer"
              @click="navTo('/my-resume/advantage', 1)"
              v-if="!Person.detail || !Person.detail.advantage"
            >
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.detail">
            {{
              Person.detail.advantage
                ? Person.detail.advantage
                : "展示您的个人优势，获得更多关注"
            }}
          </div>
        </div>
        <!-- 求职意向 -->
        <div class="addition activeTextr">
          <div class="addition_top" v-if="Person.job_intention.length == 0">
            <div class="top_left">求职意向</div>
            <div class="top_right pointer" @click="navTo('/my-resume/objective', 1)">
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.job_intention.length == 0">
            当前暂时无求职意向，添加后将给您推荐匹配度更高的职位
          </div>
          <div class="addition_top" v-if="Person.job_intention.length > 0">
            <div class="top_left">求职意向</div>
          </div>
          <div class="addition_ups" v-for="item in Person.job_intention" :key="item.id">
            {{ item.one_position }} ｜ {{ item.salary_min }}-{{ item.salary_max }} ｜
            {{ item.position_name }} ｜ {{ item.province }}
          </div>
        </div>
        <!-- 工作经历 -->
        <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">工作经历</div>
            <div
              class="top_right pointer"
              @click="navTo('/my-resume/experience', 1)"
              v-if="Person.work.length == 0"
            >
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.work.length == 0">
            添加您的过往工作经历，展示您的工作能力与成就
          </div>
          <div class="addition_ups-one" v-else>
            <div v-for="item in Person.work" :key="item.id" class="addition_ups-one-item">
              <div class="row-center font-weight-bolder text-main addition_ups-one-heard">
                <div>{{ item.company_name }}</div>
                <div>{{ item.job_times }}</div>
              </div>
              <div class="font-weight-bolder text-main addition_ups-one-title">
                {{ item.work_name }}
              </div>
              <div class="row text-main font-normal">
                <div>内容：</div>
                <div class="workContent">
                  <div v-for="(workContent, index) in item.splitAdd" :key="index">
                    {{ workContent }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 教育经历 -->
        <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">教育经历</div>
            <div
              class="top_right pointer"
              @click="navTo('/my-resume/educational', 1)"
              v-if="Person.school.length == 0"
            >
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.school.length == 0">
            添加您的教育经历，企业更加了解您
          </div>
          <div class="addition_up-one" v-else>
            <div class="row-center font-normal text-main">
              {{ Person.school[0].school_name }} ｜ {{ Person.school[0].school_times }}
            </div>
            <div class="font-normal text-main addition_up-one-title">
              {{ Person.school[0].major }} ｜ {{ Person.school[0].education }}
            </div>
            <div class="row addition_up-one-bottom font-normal text-main">
              <div>在校经历：</div>
              <div class="workContent-one">
                <div v-for="(workContent, index) in experience_one" :key="index">
                  {{ workContent }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 资格证书 -->
        <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">资格证书</div>
            <div
              class="top_right pointer"
              @click="navTo('/my-resume/certificate', 1)"
              v-if="!Person.certificate"
            >
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div v-if="Person.certificate">
            <div
              class="addition_up"
              v-for="(item, index) in Person.certificate.certificates"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="addition_up"
            v-if="Person.certificate && Person.certificate.certificates.length == 0"
          >
            暂未添加
          </div>
        </div>
      </div>
    </div>
    <mask-preview ref="maskPreview"></mask-preview>
  </div>
</template>

<script>
import apiTool from "../../../api/tool";
import api from "../../../api/user";
import maskPreview from "../components/preview.vue";
export default {
  components: { maskPreview },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      updateShow: true,
      jobList: [], //求职状态
      identity: [], //人才身份
      addressList: [], //地址
      query: {
        name: "", //姓名
        job_status: "", //当前求职状态
        gender: 1, //性别 1男2女
        identity: "", //人才身份
        birthday: "", //生日
        work_time: "", //工作日期
        s_phone: "", //手机号码，
        province_id: "", //省id
        province: "", //省，
        city_id: "", //市id
        city: "", //市
        s_email: "",
        wx_account: "",
      },
      queryOne: {
        resume_status: 1, //简历状态设置 1 公开 2 仅投递企业 3 隐藏
        protect_name: 0, //姓名保护
        protect_phone: 0, //手机号码保护
      },
      nameShow: false,
      phoneShow: false,
      Person: null, //简历相关的数据
      addressValue: [], //地址回显数据
      value4: "",
      value5: "",
      checked: "",
      checkeds: "",
      experience_one: [],
      avatarShow: false,
      avatar_url: "",
    };
  },

  created() {
    this.toolist();
    this.addressClick();
    this.getPrivacy();
    this.getResume();
  },
  watch: {},
  mounted() {},

  methods: {
    previewClick() {
      if (!this.Person.detail || this.Person.detail.completeness < 90) {
        this.$util.msg("你的简历完善度小于90%，请继续完善简历", "warning");
        return;
      }
      this.$refs.maskPreview.show();
    },
    // 上传头像
    uploadMainImg(file) {
      if (
        file.file.type != "image/png" &&
        file.file.type != "image/jpg" &&
        file.file.type != "image/jpeg"
      ) {
        this.$util.msg("请上传png,jpg,jpeg格式的图片", "error");
        return;
      }
      const isLt2M = file.file.size / 1024 / 1024 < 6;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
        return;
      }
      // 创建一个formData对象实例的方式
      const data = new FormData();
      data.append("iFile", file.file);
      apiTool
        .uploadImg(data)
        .then((res) => {
          this.avatar_url = res.data.file_path;
          api.setUserBasic({ avatar_url: res.data.file_path }).then((res) => {
            this.$util.msg("修改头像成功");
          });
        })
        .catch((err) => {
          this.$message({
            showClose: true,
            message: "上传失败",
            type: "error",
          });
        });
    },
    //完成
    submitClick() {
      api.setResume(this.query).then((res) => {
        if (res.code == 200) {
          this.$util.msg("编辑成功");
          this.getResume();
          this.updateShow = !this.updateShow;
        }
      });
    },
    // 取消
    goBack() {
      if (this.updateShow) {
        this.$router.go(-1);
      } else {
        this.updateShow = !this.updateShow;
      }
    },
    // 获取简历信息
    getResume() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api.getResume().then((res) => {
        if (res.code == 200) {
          let detail = res.data.detail;
          this.Person = res.data;
          if (detail) {
            this.query.s_phone = detail.s_phone;
            this.query.name = detail.name;
            this.query.job_status = detail.job_status;
            this.query.gender = detail.gender;
            if (detail.identity !== 0) {
              this.query.identity = detail.identity;
            }

            this.query.birthday = detail.birthday;
            this.query.work_time = detail.work_time;
            this.query.province_id = detail.province_id;
            this.query.province = detail.province;
            this.query.city_id = detail.city_id;
            this.query.city = detail.city;
            this.query.s_email = detail.s_email;
            this.query.wx_account = detail.wx_account;
            this.addressValue.push(detail.province_id);
            this.addressValue.push(detail.city_id);
          }
          if (res.data.school.length > 0) {
            if (res.data.school[0].experience) {
              this.experience_one = res.data.school[0].experience.split("\n");
            }
          }
          if (res.data.work.length > 0) {
            res.data.work.forEach((item) => {
              if (item.to_hide == 1) {
                item.to_hide = true;
              } else {
                item.to_hide = false;
              }
              if (item.work_content) {
                item.splitAdd = item.work_content.split("\n");
              }
            });
          }
        }
        loading.close();
      });
    },
    // 姓名保护
    nameChange() {
      if (this.nameShow) {
        this.queryOne.protect_name = 1;
      } else {
        this.queryOne.protect_name = 0;
      }
      this.setPrivacy();
    },
    // 手机号码保护
    phoneChange() {
      if (this.phoneShow) {
        this.queryOne.protect_phone = 1;
      } else {
        this.queryOne.protect_phone = 0;
      }
      this.setPrivacy();
    },
    // 设置隐私
    setPrivacy() {
      api.setPrivate(this.queryOne).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg);
        }
      });
    },
    // 获取设置
    getPrivacy() {
      api.getPrivate().then((res) => {
        if (res.code == 200) {
          let data = res.data;
          this.queryOne.protect_name = data.protect_name;
          this.queryOne.protect_phone = data.protect_phone;
          this.queryOne.resume_status = data.resume_status;
          this.queryOne.shield_company_ids = data.shield_company_ids;
          if (data.protect_name == 1) {
            this.nameShow = true;
          }
          if (data.protect_phone == 1) {
            this.phoneShow = true;
          }
        }
      });
    },
    navTo(path, id) {
      this.$router.push({ path, query: { id } });
    },
    // 选择性别
    genderClick(index) {
      this.query.gender = index;
    },
    // 获取城市地址
    addressClick() {
      apiTool.getAddress().then((res) => {
        let region = res.data.children;
        region.forEach((element) => {
          element.children.forEach((item) => {
            item.children = "";
          });
        });
        this.addressList = region;
      });
    },
    // 获取配置
    toolist() {
      apiTool.getUserConfig().then((res) => {
        if (res.code == 200) {
          this.jobList = res.data.job_status.options;
          this.identity = res.data.identity.options;
        }
      });
    },
    // 选择地址
    handleChange(e) {
      this.query.province_id = e[0];
      this.query.city_id = e[1];
      const obj = this.$refs.addressCascader.getCheckedNodes();
      this.query.province = obj[0].pathNodes[0].label;
      this.query.city = obj[0].label;
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 51px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #f3f3f3;
  border-bottom: 2px solid #f3f3f3;
  .title_jl {
    font-size: 14px;
    font-weight: 400;
    color: #414a60;
    margin-left: 30px;
  }
  .title_yl {
    font-size: 14px;
    font-weight: 400;
    color: #126bf9;
    margin-right: 30px;
  }
}
.active {
  background: #ffffff;
  overflow: hidden;
  .activeText {
    margin: 17px 0px 30px;
    .activeName {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nameleft {
        font-size: 24px;
        font-weight: 400;
        color: #24272e;
      }
      .el-nameimg-posi {
        .el-nameimg {
          border-radius: 50%;
          width: 56px;
          height: 56px;
          overflow: hidden;
        }
        .el-divider {
          width: 56px;
          height: 56px;
          top: 0;
          left: 0;
          border-radius: 50%;
          background-color: hsla(0, 0%, 51.4%, 0.8);
          background-image: url(https://static.zhipin.com/zhipin-geek/chat/v125/static/images/icons-resume.f44cb025.png);
          background-repeat: no-repeat;
          background-position: center -780px;
          /deep/.upload-demo {
            width: 56px;
            height: 56px;
          }
          /deep/.el-upload {
            width: 56px;
            height: 56px;
            border-radius: 50%;
          }
        }
      }
    }
    .activeExperience {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .active_xt {
        width: 2px;
        height: 12px;
        background: #e0e0e0;
        margin: 0 14px 0 21px;
      }
      .active_jingyan {
        display: flex;
        align-items: center;
        .experience_icon {
          width: 14px;
          height: 14px;
        }
        .experience {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #414a60;
        }
      }
      img {
        width: 13px;
        height: 12px;
        margin-right: 6px;
      }
    }
    .redact {
      width: 100%;
      background: #f8f9fb;
      .redact_title {
        font-size: 16px;
        font-weight: 400;
        color: #414a60;
      }
      .redact_xinxi {
        display: flex;
        align-items: center;
        .xinxi_left {
          .xinxi_xingming {
            margin-top: 25px;
            font-size: 14px;
            font-weight: 400;
            color: #495060;
          }
          .xinxi_flex {
            width: 345px;
            margin-top: 11px;
            .flex_input {
              width: 231px;
              height: 38px !important;
              line-height: 38px !important;
            }
            .flex_tab {
              margin-left: 11px;
              font-size: 13px;
              font-weight: 400;
              color: #414a60;
            }
          }
        }
        .xinxi_right {
          margin-left: 180px;
          .xinxi_xingming {
            margin-top: 25px;
            font-size: 14px;
            font-weight: 400;
            color: #495060;
          }
          .xinxi_flex {
            width: 345px;
            margin-top: 11px;
          }
        }
      }
    }
    .addition {
      margin: 50px 0 0 0;
      .addition_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top_left {
          font-size: 18px;
          font-weight: 400;
          color: #414a60;
        }
        .top_right {
          display: flex;
          align-items: center;
          .right_blue {
            width: 13px;
            height: 13px;
            background-color: #126bf9;
          }
          .right_tianjia {
            font-size: 13px;
            font-weight: 400;
            color: #126bf9;
            margin-left: 10px;
          }
        }
      }
      .addition_ups {
        margin-top: 1.875rem;
        font-size: 0.875rem;
        font-weight: 400;
        color: #414a60;
      }
      .addition_up {
        margin-top: 30px;
        font-size: 14px;
        font-weight: 400;
        color: #aeb1bb;
      }
      .addition_up-one {
        margin-top: 28px;
        .addition_up-one-title {
          margin: 20px 0;
        }
        .addition_up-one-bottom {
          > div:first-child {
            font-size: 15px;
          }
          .workContent-one {
            line-height: 23px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.activeTextr {
  padding: 0 30px;
}
.active_bianji {
  padding: 28px 30px 25px;
}
</style>
<style lang="less">
.flex_input {
  width: 100%;
  height: 38px !important;
  line-height: 38px !important;
  .el-input__inner {
    width: 100% !important;
    height: 100% !important;
    line-height: 100% !important;
  }
}
.flex_inputs {
  width: 100% !important;
}
.xingming_nan {
  width: 147px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e3e7ed;
  font-size: 14px;
  font-weight: 400;
  color: #9fa3b0;
  line-height: 38px;
  text-align: center;
}
.gender-index {
  background: #126bf9;
  color: #ffffff;
}
.flex_inputr {
  width: 315px;
}
.qzxz_right {
  .right_qx {
    width: 92px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #e3e7ed;
    font-size: 14px;
    font-weight: 400;
    color: #414a60;
    text-align: center;
    line-height: 36px;
  }
  .right_wc {
    width: 92px;
    height: 36px;
    background: #126bf9;
    border: 1px solid #e3e7ed;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 36px;
    margin-left: 20px;
  }
}
.addition_ups-one {
  margin-top: 28px;
  .addition_ups-one-item {
    margin-bottom: 20px;
  }
  .addition_ups-one-item:last-child {
    margin-bottom: 0;
  }
  .addition_ups-one-heard {
    font-size: 15px;
    div:last-child {
      font-weight: 400;
      color: #9fa3b0;
      font-size: 13px;
      margin-left: 20px;
    }
  }
  .addition_ups-one-title {
    font-size: 15px;
    margin: 20px 0px;
  }
  .workContent {
    line-height: 23px;
  }
}
</style>
